import { Fragment } from 'react';
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import PrivateComponent from './privatecomponent';
import PublicElement from './publicelement';

import Login from '../screen/login/Login';
import FindPassword from '../screen/login/findpassword';
import ResetPassword from '../screen/login/ResetPassword';
//대시보드
import Dashboard from '../screen/dashboard/Dashboard';
//교육상품
import EduLeasson from '../screen/education/eduleasson';//교육 상품
import NewLeasson from '../screen/education/newLeasson';//신규 교육 등록
import DetailLeasson from '../screen/education/detailLeasson';// 교육 상품 상세
import CrmQueryProd from '../screen/education/crmqueryprod';//상품문의
import DetailQueryProd from '../screen/education/detailQueryProd';//상품문의 상세
//회사
import DetailCompany from '../screen/company/detailcompany';//회사정보
import Editcompany from '../screen/company/editcompany';
import Linetok from '../screen/company/linetok';//한줄톡
import DetailLinetok from '../screen/company/detailLinetok';//한줄톡 상세
import ManagerList from '../screen/manager/mnglist';
import DetailManager from '../screen/manager/detailManager'

import ChangePassword from '../screen/mypage/changepassword';
import AlermList from '../screen/mypage/alermlist';
import Reviews from '../screen/crm/reviews';
import PremiumReviews from '../screen/crm/premiumReviews';
import UnmatchedReviews from '../screen/crm/unmatchedReviews';
import NDetailCommunity from '../screen/board/detail/NDetailCommunity';
import Board from '../screen/board/BoardContainer';

export default function Router() {
    let url = useLocation();
    let paths = [
        "/login",
        "/findpsw",
        "/reset-password",
        "/dashboard",//대시보드
        "/mypage/changepassword",
        "/mypage/alermlist",
        "/education/leasson",
        "/education/leasson/new",
        "/education/leasson/detail",
        "/education/queryprod",
        "/education/queryprod/detail",
        "/company/detailcompany",
        "/company/editcompany",
        "/company/linetok",
        "/company/linetok/detail",
        "/company/manager/list",
        "/company/manager/detail",
        "/crm/reviews", // 수강후기
        "/crm/premiumReviews", // 프리미엄수강후기
        "/crm/unmatchedReviews", // 매칭필요수강후기
    ];

    return (
        <Routes>
            <Fragment>
                <Route path="/" element={<Navigate to="/login" />} exact/>

                {/* Public Block */}
                <Route path="/login" exact element={<PublicElement  element={<Login />}/>} />
                <Route path="/findpsw" exact element={<PublicElement  element={<FindPassword />}/>} />
                <Route path="/reset-password" exact element={<PublicElement element={<ResetPassword />}/>} />
                
                {/* Private Block */}
                <Route path="/dashboard" exact element={<PrivateComponent  element={<Dashboard />}/>} />
                {/* 교육상품 */}
                <Route path="/education" exact >
                    <Route path="leasson" exact element={<PrivateComponent  element={<EduLeasson />}/>} />
                    <Route path="leasson/new" exact element={<PrivateComponent  element={<NewLeasson />}/>} />
                    <Route path="leasson/detail" exact element={<PrivateComponent  element={<DetailLeasson />}/>} />
                    <Route path="queryprod" exact element={<PrivateComponent  element={<CrmQueryProd />}/>} />
                    <Route path="queryprod/detail" exact element={<PrivateComponent  element={<DetailQueryProd />}/>} />
                    {url.pathname.indexOf("/education") === 0 && (<Route path={url.pathname} exact element={<Navigate to="/education/leasson" />} />)}
                </Route>
                {/* 회사관리 */}
                <Route path="/company" exact >
                    <Route path="detailcompany" exact element={<PrivateComponent  element={<DetailCompany />}/>} />
                    <Route path="editcompany" exact element={<PrivateComponent  element={<Editcompany />}/>} />
                    <Route path="linetok" exact element={<PrivateComponent  element={<Linetok />}/>} />
                    <Route path="linetok/detail" exact element={<PrivateComponent  element={<DetailLinetok />}/>} />
                    <Route path="manager/list" exact element={<PrivateComponent element={<ManagerList />}/>} />
                    <Route path="manager/list/detail" exact element={<PrivateComponent element={<DetailManager />}/>} />
                    {url.pathname.indexOf("/company") === 0 && (<Route path={url.pathname} exact element={<Navigate to="/company/detailcompany" />} />)}
                </Route>

                <Route path="/mypage" exact >
                    {/* <Route path="" exact element={<PrivateComponent  element={<Mypage />}/>} /> */}
                    {/* <Route path="editmyinf" exact element={<PrivateComponent  element={<EditMyInfo />}/>} /> */}
                    <Route path="changepassword" exact element={<PrivateComponent  element={<ChangePassword />}/>} />
                    <Route path="alermlist" exact element={<PrivateComponent  element={<AlermList />}/>} />
                    {url.pathname.indexOf("/mypage") === 0 && (<Route path={url.pathname} exact element={<Navigate to="/mypage/changepassword" />} />)}
                </Route>
                {/* CRM */}
                <Route path="/crm" exact >
                    <Route path="reviews" exact element={<PrivateComponent element={<Reviews />}/>} />
                    <Route path="premiumReviews" exact element={<PrivateComponent element={<PremiumReviews />}/>} />
                    <Route path="unmatchedReviews" exact element={<PrivateComponent element={<UnmatchedReviews />}/>} />
                    {url.pathname.indexOf("/crm") === 0 && (<Route path={url.pathname} exact element={<Navigate to="/crm/reviews" />} />)}
                </Route>

                <Route path="board" exact element={<PrivateComponent element={<Board />}/>} />
                <Route path="board/detail" exact element={<PrivateComponent element={<NDetailCommunity />}/>} />



                {/* Other Block */}
                {paths.includes(url.pathname) ? <></> : <Route path={url.pathname} exact element={<Navigate to="/dashboard" />} />}
            </Fragment>
        </Routes>
    );
}
