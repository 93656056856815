import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { Provider as StoreProvider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "@fontsource/noto-sans-kr";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "quill/dist/quill.snow.css";

function App() {
  const presistor = persistStore(store);
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 1, refetchOnWindowFocus: false } },
  });

  return (
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate
          // loading={<div className="spinner-border loader" style={{ position: "fixed", top: "50%", left: "50%", width: "5rem", height: "5rem" }} role="status"></div>}
          persistor={presistor}
        >
          {/* <BrowserRouter basename='partner'> */}
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </QueryClientProvider>
    </StoreProvider>
  );
}

export default App;
