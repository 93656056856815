import React, { useRef, useState } from "react";
import Leftmenu from "../../../layout/leftmenu";
import consts from "../../../libs/consts";
import SmartEditor from "../../../components/smarteditor";
import "./community-detail.css";
import { toastMsg } from "../../../libs/utils";
import { requestFile } from "../../../libs/request";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../utils/Axios";
import withRouter from "../../../components/withRouter";
import ConformModal from "../../../components/modal/ConformModal";
import QuillEditor from "../../../components/editor/QuillEditor";

function NDetailCommunity({ router }) {
  const { searchParams, navigate } = router;
  const postId = searchParams.get("id");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModal = () => {
    setIsOpenModal((prev) => !prev);
  };
  const editorRef = useRef(null);
  const fileRef = useRef(null);
  const editorImgRef = useRef(null);
  const [input, setInput] = useState({
    status: 1,
    title: "",
    content: "",
    main_img: "",
  });
  const { data } = useQuery({
    queryKey: ["community-detail", postId],
    queryFn: () => Axios.get(`partner/community/${postId}`),
    select: (v) => v.data,
    enabled: !!postId,
    onSuccess: (fetchedData) => {
      setInput({
        status: fetchedData.status ?? 1,
        title: fetchedData.title ?? "",
        content: fetchedData.content ?? "",
        main_img: fetchedData.main_img ?? "",
      });
    },
  });

  const handleChangeEditor = (v) => {
    setInput((prev) => ({ ...prev, content: v }));
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;

    // 값의 타입을 적절히 변환하여 상태 업데이트
    setInput((prevInput) => {
      let updatedValue = value;
      if (name === "status") {
        updatedValue = parseInt(value, 10);
      }
      return {
        ...prevInput,
        [name]: updatedValue,
      };
    });
  };

  const openfileSelect = () => {
    fileRef.current.click();
  };

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    // get dementions
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let width = img.width;
    let height = img.height;

    if (width != 720 || height != 378) {
      toastMsg("이미지를 720px X 378px로 권장 드립니다.");
    }

    const formData = new FormData();
    formData.append("file", file);
    requestFile("uploadImage", formData, (res) => {
      setInput((prev) => ({ ...prev, main_img: res.file_path }));
    });
  };

  const handleImgFileChange = (event) => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    requestFile("uploadImage", formData, (res) => {
      const { file_path, originalname } = res;

      let imgHTML = `<img src="${
        consts.resourcUrl + file_path
      }" style="width: 100%">`;
      editorRef.current.addContext(imgHTML);
    });
  };

  const handleUpload = async () => {
    // const preContent = editorRef.current.getContext()
    const preContent = input.content
      .replace(/<[^>]*>/g, "")
      .replace(/&amp;/gi, "&")
      .replace(/&nbsp;/gi, " ")
      .slice(0, 100);

    if (!input.main_img) {
      alert("이미지를 등록해주세요");
      return;
    }

    if (!input.title) {
      alert("제목을 등록해주세요");
      return;
    }

    try {
      if (postId) {
        await Axios.patch("partner/community", {
          ...input,
          // content: editorRef.current.getContext(),
          post_type: "edu",
          community_id: postId,
          pre_content: preContent,
        });
      } else {
        await Axios.post("partner/community", {
          ...input,
          // content: editorRef.current.getContext(),
          post_type: "edu",
          pre_content: preContent,
        });
      }

      alert("저장되었습니다.");

      router.navigate("/board");
    } catch (err) {
      alert("에러가 발생했습니다.");
      console.error(err);
    }
  };

  const handlePostDelete = async () => {
    try {
      await Axios.delete("admin/community", {
        data: {
          community_id: postId,
        },
      });

      router.navigate(`/board`);
    } catch (err) {
      alert("에러가 발생했습니다.");
      console.error(err);
    }
  };

  return (
    <Leftmenu>
      <div className="page_contents detailleasson">
        {/* <UsePrompt isDirty={JSON.stringify(org_state) != JSON.stringify(state1) && !saved} /> */}
        <div className="card">
          <div className="card_head">
            <div className="card_head_title">
              게시글 {Boolean(postId) ? "상세" : "작성"}
            </div>
            <div className="fralign_center" style={{ gap: 10 }}>
              {Boolean(postId) || (
                <div className="btn_normal cancel" onClick={() => navigate(-1)}>
                  취소
                </div>
              )}
              <div className="btn_normal" onClick={() => handleUpload()}>
                {postId ? "수정" : "등록"}
              </div>
            </div>
          </div>
          <div className="card_body">
            <div
              className="frjustify_between splite_head  pt-10"
              style={{ width: "100%" }}
            >
              <div className="font_D700_16">기본 정보</div>
            </div>
            {
              <>
                <div className={`card_row`} style={{ alignItems: "stretch" }}>
                  <div style={{ flex: 1 }}>
                    {postId > 0 && (
                      <div className={`card_row`} style={{ borderTop: 0 }}>
                        <label className="field_label">게시글 ID</label>
                        <div className="card_row_val">{postId}</div>
                      </div>
                    )}

                    <div className={`card_row`}>
                      <label className="field_label">게시글 상태</label>
                      <div className="card_row_val">
                        <label className="radio_label">
                          <input
                            type="radio"
                            checked={input.status === 1}
                            onChange={handleChangeInput}
                            name="status"
                            value={1}
                          />
                          공개
                        </label>
                        <label className="radio_label">
                          <input
                            type="radio"
                            checked={input.status === 0}
                            onChange={handleChangeInput}
                            name="status"
                            value={0}
                          />
                          비공개
                        </label>
                      </div>
                    </div>
                    <div className={`card_row `}>
                      <label className="field_label">글제목</label>
                      <div className="card_row_val">
                        <input
                          type="text"
                          className="input_box"
                          placeholder="글제목을 입력하세요"
                          value={input.title}
                          style={{ width: 330 }}
                          onChange={handleChangeInput}
                          name="title"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="fcjust_center pt-0"
                    style={{
                      width: 300,
                      alignItems: "center",
                      borderLeft: "1px solid #EEEEEE",
                      padding: 10,
                      gap: 6,
                      boxSizing: "border-box",
                    }}
                  >
                    <label className={`field_label w-100 pt-3 `}>
                      썸네일이미지<span>*</span>{" "}
                      <span className="gray-text fs-11 ms-2 mt--2">
                        png, jpg 파일, 290px X 180px 권장
                      </span>
                    </label>
                    <input
                      type="file"
                      ref={fileRef}
                      name="main_img"
                      onChange={handleFileChange}
                      className="d-none"
                      accept="image/*"
                      hidden
                    />
                    {/* <div className='prod_img_bg' style={{ backgroundImage: 'url(' + consts.resourcUrl + thumb + ')' }}></div> */}
                    {Boolean(input.main_img) ? (
                      <img
                        src={consts.resourcUrl + input.main_img}
                        className="prod_img_bg"
                      />
                    ) : (
                      <div className="prod_img_bg" />
                    )}
                    <div className="btn_normal" onClick={openfileSelect}>
                      이미지등록
                    </div>
                  </div>
                </div>
              </>
            }

            <div className={`frjustify_between splite_block w-100 py-3 `}>
              <div className="font_D700_16">게시글 본문</div>
            </div>
            <div className="card_row_val">
              {/* <div
                className="btn_normal"
                onClick={() => editorImgRef.current.click()}
              >
                이미지 추가
              </div>
              <span className="gray-text fs-11 ms-2 mt--2">
                png, jpg 파일, 1280px X 672px 권장
              </span>
              <input
                ref={editorImgRef}
                type="file"
                name="thumbinfo"
                onChange={(e) => handleImgFileChange(e)}
                className="d-none"
                accept="image/*"
              /> */}
            </div>
            {/* <SmartEditor
              ref={editorRef}
              editorid={"class_intro"}
              value={input.content}
              onChange={(val) => {
                console.log(" SmartEditor : ", val);
              }}
            /> */}
            <QuillEditor
              content={input.content}
              handleChangeEditor={handleChangeEditor}
            />
          </div>
        </div>
        {/* {postId > 0 && (
          <div className="fr_c_end w-100 py-3">
            <div
              className="btn_remove"
              style={{ width: 120 }}
              onClick={() => setIsOpenModal(true)}
            >
              게시글 삭제
            </div>
          </div>
        )} */}
      </div>
      {isOpenModal && (
        <ConformModal
          closeModal={() => handleModal()}
          onConfirm={() => handlePostDelete()}
          title={"삭제하기"}
        >
          {"등록된 게시글을 \n삭제하시겠습니까?"}
        </ConformModal>
      )}
    </Leftmenu>
  );
}

export default withRouter(NDetailCommunity);
