import React, { useState } from "react";
import Leftmenu from "../../layout/leftmenu";
import { formatNum } from "../../libs/utils";
import withRouter from "../../components/withRouter";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../utils/Axios";
import Nodata from "../../components/Nodata";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";

function Board({ router }) {
  const { searchParams, setSearchParams, navigate } = router;
  const [page, setPage] = useState(1);

  const [searchKeyWord, setSearchKeyWord] = useState("");
  const searchSubmitHandler = (e) => {
    e.preventDefault();

    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("keyword", searchKeyWord);
    setSearchParams(updatedParams);
    setSearchKeyWord("");
  };

  const status = searchParams.get("status") || "";
  const keyword = searchParams.get("keyword") || "";
  const { data } = useQuery({
    queryKey: ["partner-community-list", status, keyword, page],
    queryFn: () =>
      Axios.get(`partner/community`, {
        params: {
          page,
          limit: 20,
          ...(status && { status }),
          ...(keyword && { keyword }),
        },
      }),
    select: (v) => v.data,
  });

  const onChangeHandler = (e) => {
    setSearchKeyWord(e.target.value);
  };
  const handleSortChange = (v) => {
    if (v === 1 || v === 0) {
      setSearchParams({ ...Object.fromEntries(searchParams), status: v });
    } else {
      const params = new URLSearchParams(searchParams);
      params.delete("status");
      setSearchParams(params);
    }
  };

  const getData = (page) => {
    setPage(page);
  };
  return (
    <React.Fragment>
      <Leftmenu>
        <div className="page_contents detailreport">
          <div className="card">
            <div className="card_head">
              <div className="card_head_title">게시글 관리</div>
              <div className="card_head_tool">
                <div className="search_input">
                  <form
                    onSubmit={searchSubmitHandler}
                    style={{ width: "100%" }}
                  >
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      className="text search"
                      value={searchKeyWord}
                      onChange={onChangeHandler}
                      maxLength={40}
                      placeholder="게시글ID, 제목, 작성자 닉네임으로 검색"
                    />
                  </form>

                  <div
                    className="search_btn"
                    onClick={(e) => searchSubmitHandler(e)}
                  />
                </div>
                <div
                  className="btn_normal"
                  onClick={() => router.navigate("/board/detail")}
                >
                  게시글 등록
                </div>
              </div>
            </div>
            <div className="card_head">
              <div className="table_total">
                {/* <span>{formatNum(totalCnt)}</span>개의 후기 */}
              </div>
              <div className="fralign_center" style={{ gap: 60 }}>
                <div className="fralign_center" style={{ gap: 20 }}>
                  <div className="font_D700_14">게시글 상태</div>
                  <label
                    className="radio_label"
                    onClick={() => handleSortChange(10)}
                  >
                    <input
                      type="radio"
                      name="review_st"
                      checked={!status}
                      onChange={() => {}}
                    />
                    전체
                  </label>
                  <label
                    className="radio_label"
                    onClick={() => handleSortChange(1)}
                  >
                    <input
                      type="radio"
                      name="review_st"
                      checked={status === "1"}
                      onChange={() => {}}
                    />
                    공개
                  </label>
                  <label
                    className="radio_label"
                    onClick={() => handleSortChange(0)}
                  >
                    <input
                      type="radio"
                      name="review_st"
                      checked={status === "0"}
                      onChange={() => {}}
                    />
                    비공개
                  </label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>게시글 ID</th>
                  <th>글제목</th>
                  <th>게시글 상태</th>
                  <th>작성일시</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: "center" }}>
                {data?.items?.length > 0 ? (
                  data?.items?.map((item, index) => (
                    <tr
                      key={item.id}
                      onClick={() => {
                        navigate(`/board/detail?id=${item.id}`);
                      }}
                    >
                      <td style={{ fontSize: "12px" }}>{item.id}</td>
                      <td style={{ fontSize: "12px" }}>{item.title}</td>

                      <td style={{ fontSize: "12px" }}>
                        {item.status === 1 ? "공개" : "비공개"}
                      </td>
                      <td style={{ fontSize: "12px" }}>
                        {moment(item.reg_dtm).format("YYYY-MM-DD HH:mm")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <Nodata colSpan={3} />
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            totalCnt={data?.meta?.totalItems}
            onClick={(pageIdx) => getData(pageIdx)}
            currentPage={data?.meta?.currentPage}
            perPage={20}
          />
        </div>
      </Leftmenu>
    </React.Fragment>
  );
}

export default withRouter(Board);
